<template>
  <m-widget-container class="d-flex align-center flex-shrink-1">
    <v-row no-gutters align="stretch" class="fill-height">
      <v-col
        v-for="(item, i) in items"
        :key="i"
      >
        <v-card
          tile
          flat
          height="100%"
          :dark="dark"
          color="transparent"
          class="d-flex flex-column align-center justify-center py-5"
          @click="$emit('open-page', item.pageId)"
        >
          <v-avatar
            size="50%"
            tile
          >
            <v-icon
              size="5rem"
              v-text="`mdi-${item.icon}`"
            />
          </v-avatar>
          <h2 class="text-center" style="font-size: 1.3em;">
            {{ item.title }}
          </h2>
          <h4 class="text-center">
            {{ item.description }}
          </h4>
        </v-card>
      </v-col>
    </v-row>
  </m-widget-container>
</template>

<script>
import { mWidgetMixin } from '../../../mixins'
export default {
  name: 'MMenu',
  mixins: [mWidgetMixin],
  props: {
    items: {
      type: Array,
      default: () => []
    },
    dark: {
      type: Boolean,
      default: () => false
    }
  }
}
</script>

<style lang="sass">
  .m-header .v-toolbar__content
    align-items: center
</style>
